@charset "utf-8";
/*------------------------------------------------------------
	書式設定
------------------------------------------------------------*/
/* テキストの位置 */
.taLeft {
  text-align: left !important;
}
.taCenter {
  text-align: center !important;
}
.taRight {
  text-align: right !important;
}

/* フォントの大きさ（プラス） */
.fsP1 {
  font-size: 1.08em !important;
}
.fsP2 {
  font-size: 1.16em !important;
}
.fsP3 {
  font-size: 1.24em !important;
}
.fsP4 {
  font-size: 1.32em !important;
}
.fsP5 {
  font-size: 1.4em !important;
}

/* フォントの大きさ（マイナス） */
.fsM1 {
  font-size: 0.92em !important;
}
.fsM2 {
  font-size: 0.84em !important;
}
.fsM3 {
  font-size: 0.76em !important;
}
.fsM4 {
  font-size: 0.68em !important;
}
.fsM5 {
  font-size: 0.6em !important;
}

/* フォントの太さ */
.fwNormal {
  font-weight: normal !important;
}
.fwBold {
  font-weight: bold !important;
}

/*------------------------------------------------------------
	微調整用クラス（※多用しないこと）
------------------------------------------------------------*/
.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}

/*------------------------------------------------------------
	汎用スタイル
------------------------------------------------------------*/
* html .clearfix {
  zoom: 1;
}

* + html .clearfix {
  zoom: 1;
}

.clearfix:after {
  height: 0;
  visibility: hidden;
  content: '.';
  display: block;
  clear: both;
}

/*------------------------------------------------------------
	pageTitle
------------------------------------------------------------*/
.pageTitle {
  margin-bottom: 162px;
  min-height: 350px;
  position: relative;
  background: url(../../img/mission/page_title_bg.jpg) no-repeat center center;
  background-size: cover !important;
  opacity: 0;
  transition: opacity 0.8s ease 0.5s;
}

.pageTitle h2 {
  margin-left: -104px;
  position: absolute;
  left: 50%;
  bottom: -92px;
  opacity: 0;
  transition: opacity 0.8s ease 0.8s;
}

.pageTitle h2 embed {
  width: 197px;
}

.loaded .pageTitle,
.loaded .pageTitle h2 {
  opacity: 1;
}

/*------------------------------------------------------------
	content
------------------------------------------------------------*/
.content {
  margin: 0 auto;
  width: 800px;
}

@media all and (min-width: 0) and (max-width: 767px) {
  /*------------------------------------------------------------
		pageTitle
	------------------------------------------------------------*/
  .pageTitle {
    margin-bottom: 121px;
    min-height: 250px;
    background-image: url(../../img/mission/sp_pageT_title_bg.jpg);
  }

  .pageTitle h2 {
    margin-left: -81px;
    bottom: -66px;
  }

  .pageTitle h2 embed {
    width: 153px;
  }

  /*------------------------------------------------------------
		content
	------------------------------------------------------------*/
  .content {
    margin: 0 20px;
    width: auto;
  }
}
